import React from 'react';

import { Link } from 'gatsby';

const Card = ({ title, subtitle, image, externalLink, full, className, imageStyle, author, to = "/resources/" }) => (
  externalLink ? 
    <a target="_blank" rel="noreferrer" href={to} className={`resource-link ${full ? '-full' : ''} ${className}`}>
    <div className="container">
      <div className="image">
        <img src={image} style={imageStyle} />
      </div>
      <div className="content head-card-content">
        <div className="title">{title}</div>
        {subtitle && <div className="subtitle line-clamp">{subtitle}</div>}
        <div className="author">{author}</div>
      </div>
    </div>
  </a>
  :
  <Link to={to} className={`resource-link ${full ? '-full' : ''} ${className}`}>
    <div className="container">
      <div className="image">
        <img src={image} style={imageStyle} />
      </div>
      <div className="content">
        <div className="title line-clamp">{title}</div>
        {subtitle && <div className="subtitle">{subtitle}</div>}
        <div className="author">by {author}</div>
      </div>
    </div>
  </Link>
);

export default Card;
