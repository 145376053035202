import React from 'react';

import Layout from '../../components/Layout';
import Article from '../../components/Article';
import lukeEvans from '../../assets/images/luke.jpeg';

const articleRemote = ({ path }) => (
  <Layout pageName="default-article" path={path}>
    <div className="page">
      <div className="banner -resources">
        <div className="chip">NEWS</div>
        <h1>
          How can Fibonacci improve
          <br />
          remote working
        </h1>
        <p>
          Sharing more about your WFH reality helps your team come together
        </p>
      </div>
      <Article
        image=""
        paragraphs={[
          "Remote working has become the buzzword since the last few days. Although a number of companies have been practicing the remote working culture in the last decade, the COVID-19 situation has made a million others join the force to make it the new normal. We are seeing and experiencing what may be the future of the workforce. Millions of Americans in the field of design, artistry and creativity, technology and the IT sectors work remotely and swear by the versatility and freedom it offers. Did you know that in the U.S. alone, 4.7 million employees (3.4% of the workforce) work from home at least half the week. (Global Workplace Analytics). And 77% of remote employees say they're more productive when working from home. (CoSo Cloud). Though it sounds very exciting, remote working for a number of professionals is not always a bed of roses, and comes with its own set of challenges.",
          '“If I don’t see you working, then how do I actually know if you are working?”. More often than we think, managers lose control of what is happening with their team either due to the plethora of emails that get exchanged during the day or due to lack of communication.While it is extremely important for the managers to keep a tab on what work is happening with their team and to have a sense of security, lack of trust can be a huge motivation killer for most employees. Tracking the progress while managing employee emotions thus becomes a huge challenge with the remote workforce.',
        ]}
        quote="23.2 million victims of account fraud worldwide were hacked because they used the easily guessable password 123456."
        section1Header="Check your passwords"
        section1Content="
        Again, this may seem obvious, but the amount of people who continue to use weak passwords is staggering. The UK’s National Cyber Security Centre found that in 2019, 23.2 million victims of account fraud worldwide were hacked because they used the easily guessable password 123456. To make sure your passwords are strong, and to encourage safe online shopping habits, consider doing the following
        "
        section2Header="Scaling support and serving customers well"
        section2Content="
        You’ve found your dream home, and it has everything you want: abundant natural light, great schools nearby, and the requisite number of bathrooms to keep everyone in the family happy. But long before you collect the keys and book a moving truck—or even submit a bid—you need to have a mortgage in place. Securing that alone can be a stressful experience, as countless homeowners have discovered over the years.
        "
        author="Luke Evans"
        authorImage={lukeEvans}
      />
      <div className="topbg" />
      <div className="bottombg" />
    </div>
  </Layout>
);

export default articleRemote;
