import React from 'react';

import Card from './Card';

import quoteLogo from '../assets/images/mdi-quote.svg';
import twitter from '../assets/images/bx_bxl-twitter.svg';
import facebook from '../assets/images/bx_bxl-facebook.svg';
import linkedin from '../assets/images/bx_bxl-linkedin.svg';

const Article = ({
  image,
  paragraphs,
  quote,
  section1Header,
  section2Header,
  section1Content,
  section2Content,
  author,
  authorImage,
}) => (
  <div className="article-container">
    <div className="image" />
    <div className="content">
      {
        paragraphs.map((p) => (
          <p className="paragraph">{p}</p>
        ))
      }
      <div className="quote -browser _mt-120">
        <img className="icon" src={quoteLogo} alt="" />
        <div className="text">
          {quote}
        </div>
      </div>
      <div className="section">
        <span className="header">{section1Header}</span>
        <p className="content">{section1Content}</p>
      </div>
      <div className="section">
        <span className="header">{section2Header}</span>
        <p className="content">{section2Content}</p>
      </div>
      <div className="author-details">
        <div className="label _bold">Author</div>
        <div className="base-chip -large author">
          <img className="avatar -no-border" src={authorImage} alt="" />
          <div className="text">
            <div className="title">
              <span className="name">{author}</span>
            </div>
            <div className="subtitle">Product Success Manager, Fibonacci</div>
          </div>
        </div>
        <a
          className="base-button -inline -message message-link"
          href="mailto:luke@fibonacci.legal"
        >
          Message Luke
        </a>
      </div>
    </div>

    <div className="social-container social">
      <div className="header">Like this article? Spread the word</div>
      <div className="logos">
        <div className="container">
          <img src={facebook} alt="" />
        </div>
        <div className="container">
          <img src={twitter} alt="" />
        </div>
        <div className="container">
          <img src={linkedin} alt="" />
        </div>
      </div>
    </div>
    <h2 className="_bold divider">Keep reading</h2>
    <div className="card-gallery gallery">
      <Card
        image="#FFD5D6"
        title="Transforming multi-jurisdictional litigation with Bird & Bird"
        content="Read how fibonacci can streamline"
        to="/resources/transforming-multi-jurisdictional-litigation-with-bird-and-bird"
      />
      <Card
        image="#9184E9"
        title="From the Legal Project Manager’s mouth: Helga Butcher’s LPM journey at Barclays"
        content="Learn how to improve your legal flow"
        to="/resources/from-the-legal-project-managers-mouth"
      />
      <Card
        image="#7FCEBB"
        title="From the horse's mouth: Why not Legal Project Management?"
        content="Read how Fibonacci can streamline"
      />
    </div>
  </div>
);

export default Article;
